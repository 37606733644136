<template>
  <v-tabs-items v-model="selectedAppBarTab">
    <v-tab-item
      v-for="tab in tabs"
      :value="tab.key"
      :key="tab.key"
    >
      <BetaWarning />
      <v-container
        fluid
        class="pa-0"
      >
        <v-row
          align="center"
        >
          <v-col
            class="py-0"
          >
            <v-btn
              x-large
              height="62"
              text
              tile
              :to="{ name: 'Coupons' }"
            >
              <v-icon
                left
                size="24"
              >
                mdi-chevron-left
              </v-icon>
              {{ $t('back') }}
            </v-btn>
          </v-col>
          <v-col
            class="py-0"
          >
            <div>
              <v-slide-x-reverse-transition>
                <v-banner
                  v-if="showSuccessStatus"
                  :color="success === true ? this.$t('successLC') : $t('errorLC')"
                  dark
                  style="border-bottom-left-radius: 4px; border-top-left-radius: 4px;"
                >
                  <div v-if="success === true">
                    {{ $t('couponUpdated') }}
                  </div>
                  <div v-if="success === false">
                    {{ $t('couldNotUpdateCoupon') }}
                  </div>
                </v-banner>
              </v-slide-x-reverse-transition>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        fluid
        class="pt-0"
      >
        <v-row
          justify="center"
          v-if="tab.key === 'profile'"
        >
          <v-col
            cols="12"
            md="8"
          >
            <CouponForm
              v-model="coupon"
              @success="handleSuccess"
            />
          </v-col>
        </v-row>
        <v-row v-if="tab.key === 'orders'">
          <v-col>
            {{ $t('nothingHereYet') }}
          </v-col>
        </v-row>
        <v-row v-if="tab.key === 'redemptions'">
          <v-col>
            <v-container class="py-0">
              <v-row dense>
                <v-col>
                  <CouponRedemptions
                    :coupon="coupon"
                    :dialog="false"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>

import CouponForm from "@/components/CouponForm"
import BetaWarning from "@/components/common/BetaWarning"
import CouponRedemptions from "@/components/coupon/CouponRedemptions"


export default {
	name: 'Coupon',
	components: {
    CouponRedemptions,
		BetaWarning,
		CouponForm
	},
	data: () => ({
		coupon: null,
    showSuccessStatus: false,
    success: null
	}),
	beforeMount() {
		this.coupon = this.$store.state.coupons.find(coupon => coupon.uuid === this.$route.params.uuid)
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	},
	computed: {
		tabs: function() {
			return [
				{
					title: this.$t('profile'),
					key: 'profile',
					badge: {
						color: 'green',
						content: 0
					}
				},
				{
					title: this.$t('orders'),
					key: 'orders',
					badge: {
						color: 'red',
						content: 0,
					}
				},
        {
          title: this.$t('redemptions'),
          key: 'redemptions'
        }
			]
		},
		selectedAppBarTab: function() {
			return this.$store.state.selectedAppBarTab
		},
	},
  methods: {
    handleSuccess(status) {
      this.showSuccessStatus = true
      this.success = status
      setTimeout(() => {
        this.showSuccessStatus = false
        this.success = null
      }, 5000)
    }
  },
	mounted() {
		this.$route.meta.appBarTitle = this.coupon.label
	}
}
</script>
